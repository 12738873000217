<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user }"
  >
    <div class="page__placeholder" v-if="!user">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg
              width="73"
              height="73"
              viewBox="0 0 73 73"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="36.5"
                cy="36"
                r="24"
                stroke="#FFF7E1"
                stroke-width="2"
              />
              <g filter="url(#filter0_d_22_5658)">
                <path
                  d="M16.1604 23C13.5332 26.8866 12 31.5673 12 36.6045C12 50.0778 22.969 61 36.5 61C50.031 61 61 50.0778 61 36.6045C61 34.8685 60.8179 33.1748 60.4716 31.5413"
                  stroke="#FFCC47"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_22_5658"
                  x="5"
                  y="15.9998"
                  width="63"
                  height="52.0002"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_22_5658"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_22_5658"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user">
      <div class="content__page">
        <!-- content__page--margintop -->

        <div class="container">
          <div class="content__page--back">
            <!--
            <a @click="$router.go(-1)">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 12H5" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 19L5 12L12 5" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/>
              </svg> {{ $t('system.back') }}
            </a>
            -->
          </div>

          <h1 class="section__title section__title--account">
            <strong
              >Правила субсидирования затрат туроператоров в сфере въездного
              туризма за каждого иностранного туриста</strong
            >
          </h1>

          <div class="section--desc account__desc--subsiding">
            <h2>Глава 1. Общие положения</h2>
            <p>
              1. Настоящие Правила субсидирования затрат туроператоров в сфере
              въездного туризма за каждого иностранного туриста (далее –
              Правила) разработаны в соответствии с подпунктом 20-14) статьи 11
              Закона Республики Казахстан «О туристской деятельности в
              Республике Казахстан» и определяют порядок субсидирования затрат
              туроператоров в сфере въездного туризма за каждого иностранного
              туриста.
            </p>
            <p>
              <strong
                >2. В настоящих Правилах используются следующие основные
                понятия:</strong
              ><br />1) пассажирский купон – часть билета, представленная в виде
              электронно-цифровой записи или на бумажном носителе (бланк строгой
              отчетности), удостоверяющая заключение договора воздушной
              перевозки пассажира и его багажа;<br />2) уполномоченный орган –
              центральный исполнительный орган, осуществляющий функции
              государственного управления в области туристской деятельности;<br />3)
              места размещения туристов – гостиницы, мотели, кемпинги,
              туристские базы, гостевые дома, дома отдыха, пансионаты и другие
              здания и сооружения, используемые для проживания туристов и их
              обслуживания;<br />4) туристская операторская деятельность (далее
              – туроператорская деятельность) – предпринимательская деятельность
              физических или юридических лиц, имеющих лицензию на данный вид
              деятельности, по формированию, продвижению и реализации
              туристского продукта в сфере выездного туризма через турагентов и
              туристского продукта в сфере въездного и внутреннего туризма
              туристам, в том числе через турагентов;<br />5) туристский
              оператор (далее – туроператор) – физическое или юридическое лицо,
              осуществляющее туристскую операторскую деятельность, туристскую
              операторскую деятельность в сфере внутреннего, въездного или
              выездного туризма;<br />6) туристский продукт – комплекс всех
              необходимых туристских услуг, достаточных для удовлетворения
              потребностей туриста во время путешествия.<br />7) туристские
              ресурсы – природно-климатические, исторические,
              социально-культурные, оздоровительные объекты, включающие объекты
              туристского показа, а также иные объекты, способные удовлетворить
              духовные потребности туристов, содействовать восстановлению и
              развитию их физических сил.
            </p>

            <h2>
              Глава 2. Порядок субсидирования затрат туроператоров в сфере
              въездного туризма за каждого иностранного туриста
            </h2>
            <p>
              3. Для получения субсидий туроператор в сфере въездного туризма в
              течение текущего года направляет в уполномоченный орган заявку на
              получение субсидий (далее – заявка) в бумажной или электронной
              форме посредствам почты либо нарочно с первого рабочего дня и до 1
              декабря соответствующего календарного года по форме согласно
              приложению к настоящим Правилам за оказанные услуги реализованного
              туристского продукта в текущем финансовом году до момента подачи
              заявки.
            </p>
            <p>
              <strong>К заявке прилагаются следующие документы:</strong>
              <br />1) в случае подачи заявки юридическим лицом – копии
              учредительных документов юридического лица, в случае подачи
              заявления физическим лицом – копия документа, удостоверяющего
              личность, копия документа, подтверждающего начало деятельности в
              качестве индивидуального предпринимателя, а при подаче заявки
              представителем туроператора дополнительно представляется
              нотариально засвидетельствованная доверенность на представление
              интересов субъекта предпринимательства;<br />2) сведения об
              отсутствии (наличии) задолженностей, учет по которым ведется в
              органах государственных доходов, полученные на первое число
              месяца, предшествующего месяцу, в котором планируется подача
              заявки; <br />3) копия договора на туристское обслуживание,
              заключенного между туроператором и иностранным туристом (далее –
              Договор), составленного в соответствии с приказом Министра по
              инвестициям и развитию Республики Казахстан от 30 января 2015 года
              № 81 «Об утверждении типового договора на туристское обслуживание»
              (зарегистрирован в Реестре государственной регистрации нормативных
              правовых актов за № 10604);<br />4) копия документа,
              удостоверяющего личность иностранного туриста, а также листов к
              ним, подтверждающих факт пересечения государственной границы
              Республики Казахстан;<br />5) копия пассажирского купона на
              иностранного туриста;<br />6) копия лицензии на осуществление
              туроператорской деятельности в сфере въездного и внутреннего
              туризма;<br />7) документ, подтверждающий оплату туристского
              продукта по Договору.
            </p>
            <p>
              <strong
                >4. Для рассмотрения поступивших заявок от туроператора
                уполномоченным органом создается постоянно действующая комиссия
                по рассмотрению заявок на субсидирование затрат туроператоров за
                каждого иностранного туриста (далее – комиссия).</strong
              >
            </p>
            <p>
              Комиссия состоит из нечетного количества членов, не менее 7 (семи)
              человек из числа которых назначаются председатель и заместитель
              председателя.<br />Председатель комиссии руководит ее
              деятельностью, проводит заседания комиссии. Во время отсутствия
              председателя его функции выполняет заместитель председателя.
            </p>
            <p>
              Подготовку протоколов заседаний комиссии осуществляет секретарь,
              который не является членом комиссии.
            </p>
            <p>
              Председатель, заместитель председателя, секретарь комиссии
              назначаются из числа работников уполномоченного органа.
            </p>
            <p>
              В состав комиссии также входят представители Национальной палаты
              предпринимателей Республики Казахстан «Атамекен», отраслевых
              ассоциаций, представители бизнеса и организаций.
            </p>
            <p>
              Комиссия проводит заседания по мере поступления заявок, но не реже
              одного раза в квартал.
            </p>
            <p>
              <strong
                >5. Поступившие заявки и прилагаемые к ней документы (далее –
                пакет документов) установленные пунктом 3 настоящих Правил
                рассматриваются комиссией на их полноту в течение двух рабочих
                дней.</strong
              >
            </p>
            <p>
              В случае поступления неполного пакета документов комиссия
              возвращает их туроператору в сфере въездного туризма в сроки,
              указанные в части первой настоящего пункта с указанием перечня
              недостающих документов.
            </p>
            <p>
              При устранении неполноты пакета документов туроператор в сфере
              въездного туризма подает пакет документов повторно.
            </p>
            <p>
              <strong
                >6. В случае полноты пакета документов, установленных
                пунктом</strong
              >
            </p>
            <p>
              <strong
                >3 настоящих Правил, комиссия в течение десяти рабочих дней со
                дня их поступления рассматривает пакет документов и принимает
                одно из следующих решений:</strong
              ><br />1) о выплате субсидий туроператору в сфере въездного
              туризма в случае, не истечения сроков действия документов
              подпункта 1) пункта 3 настоящих Правил, проживания иностранного
              туриста в местах размещения туристов не менее пяти дней и четырех
              ночей, посещение иностранным туристом не менее трех туристских
              ресурсов, регистрации иностранного туриста через информационную
              систему «е-Hotel» («е-Qonaq») в местах размещения туристов;<br />2)
              об отказе в выплате субсидий туроператору в сфере въездного
              туризма, в случае не соответствия подпункту 1) настоящего пункта.
            </p>
            <p>
              <strong
                >7. Принятое решение комиссии оформляется протоколом и
                размещается на интернет-ресурсе уполномоченного органа в течение
                двух рабочих дней со дня принятия решения.</strong
              >
            </p>
            <p>
              <strong
                >8. На основании решения комиссии о выплате субсидий
                туроператору в сфере въездного туризма уполномоченный орган в
                течении шестьдесят рабочих дней осуществляет перечисление суммы
                субсидии, на расчетный счет туроператора в сфере въездного
                туризма, открытый в банке второго уровня, указанный в
                заявке.</strong
              >
            </p>
            <p>
              <strong
                >9. Субсидии выплачиваются туроператору, осуществляющего
                деятельность в сфере въездного туризма за каждого иностранного
                туриста, прибывшего в Республику Казахстан в пределах сумм,
                определенных законом о республиканском бюджете на
                соответствующий финансовый год.</strong
              >
            </p>
            <p>
              <strong
                >10. Контроль, мониторинг и реализация субсидии осуществляются в
                соответствии с бюджетным законодательством Республики
                Казахстан.</strong
              >
            </p>
          </div>

          <div class="section--link link--senter">
            <a href="/download/regulations.pdf" download=""
              >Скачать заявку на субсидирование</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../boot/axios'

export default {
  name: 'SubsidingForeign',
  data() {
    return {
      user: null,
    }
  },
  beforeCreate() {
    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })
  },
}
</script>

<style scoped></style>
